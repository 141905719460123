import styled from 'styled-components';

export const Container = styled.div`
  .body {
    font-family: ${({ theme }) => theme.fonts.regular};
    font-size: 12px;
    padding: 10px;

    .titulo {
      font-family: ${({ theme }) => theme.fonts.title};
    }

    .lista {
      margin-top: 5px;
      max-height: 50vh;
      overflow-y: auto;
      scrollbar-width: 1px;
      overflow-x: hidden;

      /* ::-webkit-scrollbar {
        padding: 10px;
        width: 10px;
        height: 10px;
        background: ${({ theme }) => theme.colors.backgroundRodape};
      } */
    }

    .card {
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-radius: ${({ theme }) => theme.borderRadiusSmall};
      border-width: 1px;
      border-style: solid;
      border-color: ${({ theme }) => theme.colors.borderColor};
      padding: 5px;

      margin-bottom: 5px;

    }

    .linha {
      flex: 1;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;

      margin-bottom: 2px;
    }

    .coluna-header {
      border-width: 0;
      border-bottom-width: 1px;
      border-style: solid;
      border-color: ${({ theme }) => theme.colors.borderColor};
    }

    .titulo-caption {
      font-family: ${({ theme }) => theme.fonts.title};
      margin-right: 5px;
      margin-left: 5px;
    }

    .div-caption-valor {
      display: flex;
      flex: 1;
    }
  }

  .legenda {
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    div {
      text-align: center;
      margin: 2px;
      margin-left: 0px;
      width: 12px;
      height: 12px;

      border-radius: 50%;
      display: inline-block;
      color: #ffffff00;
    }
    span {
      flex: 1;

      margin-right: 5px;
      vertical-align: top;
    }
  }
`;

export const DivFooterDlg = styled.div`
  display: flex;
  justify-content: center;
  padding: 0px;
  height: 45px;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;
